export const initMobileNav = () => {	
	document.querySelector('#toggle_mobile_nav').addEventListener('click', () => {
		document.querySelector('#toggle_mobile_nav').switchAttribute('state', 'open', 'closed');
		document.querySelector('#nav_container').switchAttribute('state', 'open', 'closed');
		document.querySelector('body').switchAttribute('state', 'scroll', 'noscroll');
	});
};

/*
export const initPhoneOverlay = () => { 
	const overlay = document.querySelector('#header_phone_overlay');

	document.querySelector('#toggle_phone_overlay').addEventListener('click', () => overlay.setAttribute('show', true));
	
	overlay.addEventListener('click', e => {
		if(e.target.id === "header_phone_overlay" || e.target.id === "close_phone_overlay") {
			overlay.removeAttribute('show');
		}
	});
};
*/